import { useQuery } from "@apollo/client";
import { getFragmentData, gql } from "../../../__generated__";
import TablePaginated from "../components/TablePaginated/TablePaginated";
import { useEffect, useState } from "react";
import { GridColDef, GridNoRowsOverlay, GridRowModel } from "@mui/x-data-grid";
import { StudentCustomField } from "../../../__generated__/graphql";
import { IconButton, Paper, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { format, parseISO } from "date-fns";
import { dateOnlyISO8601 } from "../../../library/date";
import { TableFilterOption } from "../components/TableFilter";
import { TableToolbarStacked } from "../components/TableToolbar";
import { useSlideInContext } from "../../Admin/components/SlideIn/context/SlideInProvider";
import { ADD_STUDENT, LAUNCH_OPTIONS } from "../../Admin/components/SlideIn/context/contentTypes";
import { SELECTED_STUDENT_FRAGMENT, StudentDataType } from "./hooks/useSelectedStudent";
import useExpireStudent from "./hooks/useExpireStudent";
import { useManageModal } from "../components/ManageModal";
import DescriptionIcon from '@mui/icons-material/Description';
import { usePlayerLobby } from "../../../context/playerContext/PlayerDataProvider";

export const STUDENTS_PAGE_QUERY = gql(`
    query StudentsPage($query: String, $first: Int, $after: String) {
        students(query: $query, first: $first, after: $after) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            edges {
                cursor
                node {
                  ... SelectedStudent_Student
                }
            }
            totalCount
        },
        locations {
            customFields {
                id
                type 
                name
            }
            totalStudents
        }
    }
`);

const createCustomField = (customField: any): GridColDef => {
  return {
    field: customField.name ?? "missing",
    headerName: customField.name ?? "missing",
    flex: 1,
    renderCell: (params) => {
      const studentCustomField = params.row.studentCustomFields.find(
        (x: StudentCustomField) => x.customField?.id === customField.id
      ) as StudentCustomField;

      return studentCustomField ? <>{studentCustomField.value}</> : null;
    },
  };
};

const mapEdgesToTable = (data: any) => {
  return {
    ...data.node,
    name: `${data.node.firstName} ${data.node.lastName}`,
    dob: format(parseISO(dateOnlyISO8601(data.node.dob)), "MM/dd/yyyy"),
  };
};

const DEFAULT_PAGE_SIZE = 100;

const TABLE_COLUMNS: GridColDef[] = [
  { field: "name", headerName: "Fullname", flex: 1, sortable: false },
  { field: "dob", headerName: "Date of Birth", flex: 1, sortable: false },
];

type StudentPageProps = {
  onBack: () => void,
  onSelectedStudent: (data: StudentDataType) => void
};

export const StudentsPage = ({ onBack, onSelectedStudent }: StudentPageProps) => {
  
  const { handleContent } = useSlideInContext();

  const { expireStudent } = useExpireStudent();

  const { openModal, closeModal } = useManageModal();

  const { changePlayer } = usePlayerLobby();

  const { loading, data, fetchMore } = useQuery(STUDENTS_PAGE_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      query: "",
      first: 100,
    },
  });

  const handleLaunch = (student: StudentDataType) => {
    changePlayer(student);
    handleContent(LAUNCH_OPTIONS)
  }

  const handleExpire = async (id: string) => {
    openModal({ 
      type: 'EXPIRE_STUDENT', 
      confirmButtonType: 'WARNING',
      confirmButtonLabel: 'Expire',
      onConfirm: async () => {
        openModal(state => ({
            ...state,
            status: 'SAVING'
        }))

        await expireStudent(id)

        closeModal();
      }
    })
  }

  const [pageTableState, setPageTableState] = useState<{
    columns: GridColDef[];
    rows: GridRowModel[];
    filteredRows: GridRowModel[];
    status: "NORMAL" | "FILTERING";
  }>({
    columns: [],
    rows: [],
    filteredRows: [],
    status: "NORMAL",
  });

  useEffect(() => {
    setPageTableState((prevState) => {
      const columns = [...TABLE_COLUMNS];

      data?.locations[0].customFields.forEach((field: any) => {
        columns.push(createCustomField(field));
      });

      columns.push({
        field: "launch",
        headerName: "Launch",
        align: "center",
        sortable: false,
        width: 100,
        renderCell: (params) => {
          const record = data?.students?.edges?.find((edge) => {
            const fragment = getFragmentData(SELECTED_STUDENT_FRAGMENT, edge.node);
            return fragment.id == params.row.id
          })

          return (
            <IconButton onClick={() => handleLaunch(record?.node as StudentDataType)}>
              <OpenInNewIcon />
            </IconButton>
          );
        },
      });

      columns.push({
        field: "details",
        headerName: "Details",
        align: "center",
        sortable: false,
        width: 100,
        renderCell: (params) => {
          const record = data?.students?.edges?.find((edge) => {
            const fragment = getFragmentData(SELECTED_STUDENT_FRAGMENT, edge.node);
            return fragment.id == params.row.id
          })

          return (
            <IconButton onClick={() => onSelectedStudent(record?.node as StudentDataType)}>
              <DescriptionIcon />
            </IconButton>
          );
        },
      });

      columns.push({
        field: "remove",
        headerName: "Remove",
        align: "center",
        sortable: false,
        width: 100,
        renderCell: (params) => {
          return (
            <IconButton disabled={params.row.expired} onClick={() => handleExpire(params.row.id)}>
                <CloseIcon
                    style={{
                    backgroundColor: params.row.expired ? "#EEE" :  "#8E1C3B",
                    borderRadius: "100%",
                    color: params.row.expired ? "#999" : "#FFF",
                    }}
                />
            </IconButton>
          );
        },
      });

      const rows = data?.students?.edges?.map(mapEdgesToTable) ?? [];
      setPageTableState((prevState) => ({
        ...prevState,
        filteredRows: [],
        rows,
      }));

      return {
        ...prevState,
        columns,
      };
    });
  }, [loading, data]);

  const handleFilter = (selectedOption: TableFilterOption) => {
    setPageTableState((prevState) => {
      const filteredRows =
        selectedOption === "ACTIVE"
          ? prevState.rows.filter((x) => !x.expired)
          : selectedOption == "EXPIRED"
          ? prevState.rows.filter((x) => x.expired)
          : prevState.rows; // Default "ALL"

      return {
        ...prevState,
        status: selectedOption === "ALL" ? "NORMAL" : "FILTERING",
        filteredRows,
      };
    });
  };

  const handleOnNew = () => {
    handleContent(ADD_STUDENT)
  }

  const totalRows =
    pageTableState.status === "FILTERING"
      ? pageTableState.filteredRows.length
      : data?.students?.totalCount ?? 0;

  useEffect(() => {
    console.log('StudentPage Loading State', loading);
  }, [loading]);

  return (
    <Paper>
      <TablePaginated 
        loading={loading}
        renderToolbar={({ searchQuery, onSearch, onSearchClear }) => (
          <TableToolbarStacked
            title="Manage Children"
            subtitle="Children"
            newButtonLabel="Add new child"
            searchQuery={searchQuery ?? ""}
            onNew={handleOnNew}
            onBack={onBack}
            onFilter={handleFilter}
            onSearch={onSearch}
            onSearchClear={onSearchClear}
          />
        )}
        slots={{
          noRowsOverlay: (data?.locations[0].totalStudents ?? 0) <= 0 
            ? NoStudentsFoundOverlay 
            : GridNoRowsOverlay
        }}
        pageInfo={data?.students?.pageInfo}
        pageSize={DEFAULT_PAGE_SIZE}
        columns={pageTableState.columns}
        rows={
          pageTableState.status === "FILTERING"
            ? pageTableState.filteredRows
            : pageTableState.rows
        }
        totalRows={totalRows}
        onFetchMore={async ({ query }) => {
          await fetchMore({
            
            variables: {
              query,
            },
          });
        }}
      />
    </Paper>
  );
};

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function NoStudentsFoundOverlay() {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography width={500}>
      You do not have any children associated with your organization.  
Select “Add New Child” to get started or contact your futuresThrive representative to facilitate a bulk upload.
      </Typography>
    </Stack>
  )
}
