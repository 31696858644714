import React, { useEffect, useCallback, useRef, useState } from "react";
import {
  GridCallbackDetails,
  GridColDef,
  GridInputRowSelectionModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSlotsComponent,
} from "@mui/x-data-grid";

import DataGridStyled from "../../../../components/StyledGrid";
import { LinearProgress, debounce } from "@mui/material";
import { PageInfo } from "../../../../__generated__/graphql";
import { FallbackCell } from "../../../../components/FallbackCell";
import { UncapitalizeObjectKeys } from "@mui/x-data-grid/internals";

export type FetchMoreCallbackArgs = Partial<{
  query: string | null;
  first: number | null;
  after: string | null;
  replace: boolean | null;
  hasNextPage: boolean;
}>;

interface DataGridState {
  page: number;
  pageSize: number;
  rowCount: number;
  columns: GridColDef[];
  rows: GridRowModel[];
}

/**
 * Check if string has a value
 *
 * @param value
 * @returns true if values exists false otherwise
 */
function hasValue(value: string): boolean {
  return value?.trim()?.length > 0 ?? false;
}

/**
 * Wrap {@link GridColDef.renderCell} if exists with {@link FallbackCell} which is used to visually handle
 * the loading state of a table using the @see loading argument.
 *
 * @param cell
 * @param loading
 * @returns
 */
function mapFallbackCell(cell: GridColDef, loading: boolean): GridColDef {
  const renderCellCallback = cell.renderCell ? cell.renderCell : () => null;
  return {
    ...cell,
    renderCell: (params: GridRenderCellParams) => {
      return loading ? (
        <FallbackCell loading={loading}>
          {renderCellCallback(params)}
        </FallbackCell>
      ) : (
        renderCellCallback(params)
      );
    },
  };
}

/**
 * Used to ensure object returned is initialized properly. The argment
 * {@link FetchMoreCallbackArgs.hasNextPage} is expected to be a boolean and not undefined,
 * despite the type lying.
 *
 * @param args
 * @returns
 */
function normalizeFetchMoreArgs(
  args: FetchMoreCallbackArgs
): FetchMoreCallbackArgs {
  return {
    ...args,
    hasNextPage: args.hasNextPage ? args.hasNextPage : false,
  };
}

interface PageTableSelectionProps {
  rowSelectionModel?: GridInputRowSelectionModel;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
}

export type RenderToolbarProps = {
  searchQuery: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchClear: () => void;
};

type TablePaginatedProps = {
  pageInfo: PageInfo | undefined;
  loading: boolean;
  pageSize: number;
  columns: GridColDef[];
  rows: GridRowModel[];
  totalRows: number;
  slots?: Omit<UncapitalizeObjectKeys<Partial<GridSlotsComponent>>, 'loadingOverlay'>
  renderToolbar?: (props: RenderToolbarProps) => void;
  toolbarComponent?: (props: any) => React.ReactNode | null;
  onFetchMore: (options: FetchMoreCallbackArgs) => void;
  isRowSelectable?: (params: GridRowParams<any>) => boolean
  selectionProps?: PageTableSelectionProps;
  disableBoxShadow?: boolean;
  checkboxSelection?: boolean;
  disableColumnSelector?: boolean;
};

export default function TablePaginated({
  pageInfo,
  loading,
  pageSize,
  selectionProps = {},
  columns,
  rows,
  totalRows,
  slots,
  onFetchMore,
  isRowSelectable,
  disableBoxShadow = false,
  checkboxSelection = false,
  renderToolbar: renderHeader,
  disableColumnSelector = true,
}: TablePaginatedProps) {
  const paginationCursorsRef = useRef<{ [page: number]: string }>({});

  const [search, setSearch] = useState<{ query: string; hasQuery: boolean }>({
    query: "",
    hasQuery: false,
  });

  const [dataGridState, setDataGridState] = useState<DataGridState>({
    page: 0,
    pageSize: pageSize,
    rowCount: 0,
    columns: [],
    rows: [],
  });

  // Because the developer may chose to pass in a lambda directly on the props
  // we need to ensure the signature is unchanged after the initial render.
  // We'll memorize the function signature on first pass, and perform
  // argument/object normalization. This is required because a use
  // effect downstream uses the fetch more as a dependecy.
  const callbackFetchMore = useCallback(
    (args: FetchMoreCallbackArgs) => onFetchMore(normalizeFetchMoreArgs(args)),
    []
  );

  useEffect(() => {
    setDataGridState((prevState) => ({
      ...prevState,
      columns: columns,
    }));
  }, [columns, loading]);

  useEffect(() => {
    setDataGridState((prevState) => ({
      ...prevState,
      rows,
    }));
  }, [rows]);

  useEffect(() => {
    // If we have a search string avoid pagination
    if (search.hasQuery) return;

    const nextCursor =
      paginationCursorsRef.current[dataGridState.page] ?? pageInfo?.endCursor;
    if (!loading && nextCursor) {
      if (paginationCursorsRef.current[dataGridState.page] !== nextCursor) {
        paginationCursorsRef.current[dataGridState.page] = nextCursor;
      }
    }
  }, [loading, search, dataGridState.page, pageInfo]);

  useEffect(() => {
    setDataGridState((prevState) => ({
      ...prevState,
      rowCount: totalRows >= 0 ? totalRows : prevState.rowCount,
    }));
  }, [totalRows]);

  useEffect(() => {
    // Reset pagination the user removes the query from search input
    if (!search.hasQuery) {
      // Need to set the page back to the first page (0) in the case the user
      // changed to other pages before using a search query.
      setDataGridState((prevState) => ({
        ...prevState,
        page: 0,
      }));

      // Get a fresh first page of data like the initial load
      callbackFetchMore({
        query: null,
        first: dataGridState.pageSize,
        after: null,
        replace: true,
      });

      return;
    }
  }, [search, callbackFetchMore]);

  const fetchMoreSearches = (searchText: string) => {
    if (!hasValue(searchText)) return;

    console.log("Firing the debounce function", searchText, search.hasQuery);
    callbackFetchMore({
      query: searchText,
      first: 25,
      after: null,
    });
  };

  // Ensure we use the same function between component renders
  const handleDebounceSearch = useCallback(
    debounce(fetchMoreSearches, 230),
    []
  );

  const handleDataGridSearch = (searchQuery: string) => {
    if (!hasValue(searchQuery)) {
      setSearch({
        query: "",
        hasQuery: false,
      });

      return;
    }

    setSearch({
      query: searchQuery,
      // The DataGrid component has an issue when searching results locally. If the
      // user is on another page other than the first, queries come up blank. To temp
      // fix this problem we use the `hasQuery` flag to switch to pagination "server"
      // put the user on the first page, and return the number of records that match
      // the user query.
      hasQuery: true,
    });

    // Only send the search request when the user has stop typing for 300ms.
    handleDebounceSearch(searchQuery);
  };

  const handlePageChange = (model: GridPaginationModel, details: GridCallbackDetails) => {
    if (model.page === 0 || paginationCursorsRef.current[model.page - 1]) {
      setDataGridState((prevState) => ({
        ...prevState,
        page: model.page,
      }));

      // Prevent over-fetching if query page size doesn't match table page size
      if (pageInfo?.hasNextPage) {
        callbackFetchMore({
          first: dataGridState.pageSize,
          after: paginationCursorsRef.current[model.page - 1] ?? pageInfo?.endCursor,
        });
      }
    }
  };

  const handlePageSizeChange = (pageSize: number) => {
    setDataGridState((prevState) => ({
      ...prevState,
      pageSize,
    }));

    callbackFetchMore({
      first: dataGridState.pageSize,
      after:
        paginationCursorsRef.current[dataGridState.page - 1] ??
        pageInfo?.endCursor,
    });
  };

  useEffect(() => {
    console.log('Loading state changed!', loading)
  }, [loading]);

  return (
    <>
      {renderHeader &&
        renderHeader({
          searchQuery: search.query,
          onSearch: (event) => handleDataGridSearch(event.target.value),
          onSearchClear: () => handleDataGridSearch(""),
        })}

      <DataGridStyled
        style={{ minHeight: 300 }}
        loading={loading}
        autoHeight
        pagination
        paginationMode={search.hasQuery ? "server" : "client"}
        paginationModel={{ page: search.hasQuery ? 0 : dataGridState.page, pageSize }}
        rows={rows}
        rowCount={
          search.hasQuery
            ? dataGridState.rows?.length ?? 0
            : dataGridState.rowCount
        }
        isRowSelectable={isRowSelectable}
        columns={dataGridState.columns}
        pageSizeOptions={[pageSize]}
        slots={{
          ...slots,
          loadingOverlay: LinearProgress,
        }}
        onPaginationModelChange={handlePageChange}
        // onPageSizeChange={handlePageSizeChange}
        {...selectionProps}
        disableBoxShadow={disableBoxShadow}
        disableColumnSelector={disableColumnSelector}
        disableColumnMenu
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={true}
      />
    </>
  );
}
