import { ReactNode, useState } from "react";
import { Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { format } from "date-fns";

import { useManageModal } from "../../../../Manage/components/ManageModal";
import { useSlideInContext } from "../context/SlideInProvider";
import { usePlayerLobby } from "../../../../../context/playerContext/PlayerDataProvider";
import usePersonAuthStore, { decodedToken } from "../../../personAuthStore";
import SendIcon from '@mui/icons-material/Send';
import { isNullOrEmpty } from "../../../../../library";

type SEND_SESSION_REQUEST_ACTION = "local" | "redirect" | "notification";

function LaunchButton({ action: type, currentAction: action, onAction, children }: { 
  action: SEND_SESSION_REQUEST_ACTION, 
  currentAction: SEND_SESSION_REQUEST_ACTION | null, 
  onAction: (action: SEND_SESSION_REQUEST_ACTION) => {}, children: ReactNode 
}) {
  return (
    <LoadingButton
      sx={{
        backgroundColor: "primary.main",
        "&:hover": {
          backgroundColor: "primary.light",
        },
        width: 180,
        marginTop: "16px",
        padding: 1
      }}
      type="submit"
      variant="contained"
      loading={type === action}
      loadingPosition="end"
      disabled={Boolean(action)}
      endIcon={<SendIcon />}
      onClick={() => onAction(type)}
    >
      { children }
    </LoadingButton>
  )
}

export default function LaunchOptions() {
  const { openModal } = useManageModal();
  const { disableClosingDrawer, toggleDrawer } = useSlideInContext();
  const { playerData } = usePlayerLobby();
  const [ launchAction, setLaunchAction ] = useState<SEND_SESSION_REQUEST_ACTION | null>(null);

  const accessToken = usePersonAuthStore((state) => state.access_token);

  const handleLaunch = async (action: SEND_SESSION_REQUEST_ACTION) => {

    // Prevent accidental closing of the application drawer
    disableClosingDrawer(true);

    if (action === "notification" && isNullOrEmpty(playerData.parentEmail)) {
      handleNoEmailFound();
      return;
    }

    setLaunchAction(action);

    if (accessToken) {

      // Sleep for a simulated 10 seconds if intent is to send an email
      if (action === "notification") {
        openModal({ type: "REMOTE_LAUNCH_INITIALIZED", eventType: 'DISABLE_BACKDROP_AND_CTA', cancelButtonLabel: "Close" });
        // await sleep(1000 * 10)
      }

      const { pid, lid } = decodedToken(accessToken);
      const resposne = await fetch(`${window.__env.API_URL}/api/session/send`, {
        method: "post",
        headers: {
          Pid: pid,
          Lid: lid,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: action,
          type: "full",
          studentId: playerData.id,
          enteredAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssXXX")
        }),
      });

      if (resposne.status === 202) {
        handleSuccessMessage();
        return;
      }

      if (resposne.status === 200) {
        const json = await resposne.json();
        window.location.replace(json.redirectUrl);
        window.sessionStorage.clear();
      }
      
    }

    disableClosingDrawer(false);
    setLaunchAction(null);
  };

  const handleNoEmailFound = () => {
    toggleDrawer();
    disableClosingDrawer(false);
    openModal({ type: "NO_EMAIL_FOUND", cancelButtonLabel: "Close" });
  };

  const handleSuccessMessage = () => {
    toggleDrawer();
    disableClosingDrawer(false);
    openModal({ type: "REMOTE_LAUNCH_SUCCESS", eventType: 'DISABLE_BACKDROP', cancelButtonLabel: "Close" });
  };

  return (
    <Grid
      container
      spacing={8}
      direction="column"
      alignItems="center"
      sx={{ padding: "36px 16px", textAlign: "center" }}
    >
      <Grid item xs={12} direction="column" alignItems="center">
        <Typography>
          Launch the child's assessment in your browser window immediately.
        </Typography>
        <LaunchButton
          action="local"
          currentAction={launchAction}
          onAction={handleLaunch}
        >
          Local Launch
        </LaunchButton>
      </Grid>
      <Grid item xs={12} direction="column" alignItems="center">
        <Typography>
          Send a secure email to the child's parent so the child can take the
          assessment remotely.
        </Typography>
        <LaunchButton
          action="notification"
          currentAction={launchAction}
          onAction={handleLaunch}
        >
          Remote Launch
        </LaunchButton>
      </Grid>
    </Grid>
  );
}
