import { z } from "zod";
import { FormBase, FormBaseProps } from "../../../components/Form/FormBase";
import { FormSubmitCallback } from "../../../components/Form";
import InputField from "../../../components/Form/InputField";
import { useEffect, useState } from "react";
import { FieldProps } from "react-final-form";

const STUDENT_FORM_FIELDS: FieldProps<string, any>[] = [
    {
      name: "firstName",
      label: "First Name",
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "middleName",
      label: "Middle Name",
      render: InputField,
      required: false,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "dob",
      label: "Date of Birth",
      type: 'date',
      shrink: false,
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "postalCode",
      label: "Zip Code",
      render: InputField,
      required: true,
      fullWidth: true,
      grid: {
        xs: 6,
      },
    },
    {
      name: "parentPhone",
      label: "Parent phone",
      render: InputField,
      fullWidth: true,
      type: 'tel',
      grid: {
        xs: 6,
      },
    },
    {
      name: "parentEmail",
      label: "Parent email",
      render: InputField,
      fullWidth: true,
      grid: {
        xs: 12,
      },
    },
  ];

export type CustomField = {
    id: string,
    type?: string
    name: string,
    value?: string
}

const customFieldsSchema = z.record(z.string(), z.object({
    id: z.string().nonempty(),
    value: z.string().nullish()
})).nullable().nullish();

const studentEditSchema = z.object({
    firstName: z.string().trim().nonempty().max(255),
    middleName: z.string().trim().max(255).nullish(),
    lastName: z.string().trim().nonempty().max(255),
    dob: z.string().trim().max(255).nullish(),
    postalCode: z.string().trim().max(255),
    parentPhone: z.string().nullish(),
    parentEmail: z.string().email().nullish(),
    customFields: customFieldsSchema
});
  
export type StudentFormCustomFieldsSchema = z.infer<typeof customFieldsSchema>;
export type StudentFormSchema = z.infer<typeof studentEditSchema>;

function createCustomFields(columns: CustomField[]): FieldProps<string, any>[] {

    // Hidden ID
    const nextColumns:FieldProps<string, any>[] = [];
    columns.forEach(field => {
        nextColumns.push({
            name: `customFields.${field.id}[id]`,
            type: "hidden",
            value: field.id,
            required: false,
        });
        nextColumns.push({
            name: `customFields.${field.id}[value]`,
            label: field.name,
            render: InputField,
            required: false,
            fullWidth: true,
            grid: {
                xs: 12,
            }
        })
    });

    return nextColumns;
}

type FormState = {
    fields: FieldProps<string, any>[]
}

export type StudentFormProps = {
    customFields: CustomField[],
    onSubmit: (values: StudentFormSchema) =>  Promise<ReturnType<FormSubmitCallback>>
    onCancel: () => void
} & Pick<FormBaseProps<StudentFormSchema>, "initialValues" | "readonly">

export default function StudentForm({ customFields, initialValues, readonly, onSubmit, onCancel }: StudentFormProps) {

    const [formState, setFormState] = useState<FormState>({
        fields: STUDENT_FORM_FIELDS
    });

    useEffect(() => {
        const fields = [...STUDENT_FORM_FIELDS].concat(createCustomFields(customFields));
        
        setFormState(prevState => ({
            ...prevState,
            fields
        }));
    }, [customFields]);

    const EditForm = FormBase<StudentFormSchema>({
        fields: formState.fields,
        initialValues: initialValues ?? {},
        validationSchema: studentEditSchema,
        buttonSubmitLabel: "Save",
        buttonCancelLabel: "Cancel",
        readonly,
        onSubmit: onSubmit,
        onCancel: onCancel
    })

    return EditForm;
}