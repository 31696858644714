import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import {
  GridRowsProp,
  GridColDef,
  GridApi,
} from "@mui/x-data-grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";
import QuickSearchToolbar from "../../../Admin/components/StudentsGrid/SearchBar";
import DataGridStyled from "../../../../components/StyledGrid";
import FilterBy from "..";
import { useFilterBy } from "../../hooks/useFilterBy";
import Header from "../Header";
import { Box, Button } from "@mui/material";
import { IUsers } from "../../types";
import AddIcon from "@mui/icons-material/Add";

function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

interface IManageTableProps {
  loading?: boolean,
  headerTitle?: string;
  rows: GridRowsProp;
  columns: GridColDef[];
  onBack?: () => void;
  title?: string;
  onExpire?: (value: any) => void;
  onAddNew?: () => void;
  onRemove?: (id: string) => void;
  handleDetails?: (details: IUsers) => void;
  onLink?: () => void;
  linkTitle?: string;
  pageSize?: number;
  selectionProps?: any;
  disableBoxShadow?: boolean;
}

export default function ManageTable({
  loading = false,
  title,
  headerTitle,
  onExpire,
  rows: data,
  onBack: handleBack,
  onAddNew: handleAddNew,
  handleDetails,
  columns: gridDef,
  onLink,
  linkTitle: onLinkTitle,
  onRemove: handleRemove,
  pageSize = 25,
  selectionProps = {},
  disableBoxShadow = false
}: IManageTableProps) {
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState<any[]>([]);
  const [dataColumns, setDataColumns] = React.useState<GridColDef[]>([]);
  const { filterBy, setFilterBy } = useFilterBy([...data], setRows);

  useEffect(() => {
    // @ts-ignore
    setRows([...data]);
  }, [data]);

  useEffect(() => {
    setDataColumns([...gridDef, ...constructTable()]);
  }, [gridDef]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = [...data].filter((row: any) => {
      return Object.keys(row).some((field: any) => {
        return row[field] ? searchRegex.test(row[field].toString()) : false;
      });
    });
    // @ts-ignore
    setRows(filteredRows);
  };

  const filterByHandler = (value: string) => setFilterBy(value);
  const constructTable = () => {
    const _columns: GridColDef[] = [];
    if (handleDetails) {
      _columns.push({
        align: "center",
        sortable: false,
        resizable: false,
        editable: false,
        field: "",
        headerName: "Details",
        width: 80,
        renderCell: (params) => {
          const onClick = () => {
            const api: GridApi = params.api;
            //@ts-ignore
            handleDetails(api.getRow(params.id));
          };
          return (
            <IconButton onClick={onClick}>
              <OpenInNewIcon />
            </IconButton>
          );
        },
      });
    }
    if (onExpire || handleRemove) {
      _columns.push({
        align: "center",
        sortable: false,
        resizable: false,
        editable: false,
        disableColumnMenu: true,
        field: "id",
        width: onExpire ? 80 : 100,
        headerName: onExpire ? "Expire" : "Remove",
        renderCell: (params) => {
          const api: GridApi = params.api;
          const onClick = () => {
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
            const thisRow: any = {};

            fields.forEach((f) => {
              thisRow[f] = params.api.getCellValue(params.id, f);
            });
            if (onExpire) {
              onExpire(thisRow.id);
            } else if (handleRemove) {
              handleRemove(thisRow.id);
            }
          };
          //@ts-ignore
          return (
            <IconButton onClick={onClick}>
              <CloseIcon
                    style={{
                      backgroundColor: params.row.expired ? "#EEE" :  "#8E1C3B",
                      borderRadius: "100%",
                      color: params.row.expired ? "#999" : "#FFF",
                    }}
                  />
            </IconButton>
          );
        },
      });
    }
    return _columns;
  };

  const renderToolbarComponent = (titleProps: any) => {
    if (headerTitle && handleBack && handleAddNew) {
      return (
        <Box component="div">
          <Header
            title={headerTitle}
            onBack={handleBack}
            onNew={handleAddNew}
          />
          <QuickSearchToolbar {...titleProps}>
            <FilterBy value={filterBy} handleChange={filterByHandler} />
          </QuickSearchToolbar>
        </Box>
      );
    } else if (headerTitle && handleBack) {
      return (
        <Box component="div">
          <QuickSearchToolbar {...titleProps}>
            <Header
              disableDivider={true}
              title={headerTitle}
              onBack={handleBack}
              onNew={handleAddNew}
            />
          </QuickSearchToolbar>
        </Box>
      );
    } else if (onLink && onLinkTitle) {
      return (
        <Box component="div">
          <QuickSearchToolbar {...titleProps} title={""}>
            <Button
              color="secondary"
              onClick={onLink}
              startIcon={
                <AddIcon
                  style={{
                    backgroundColor: "#253D93",
                    borderRadius: "100%",
                    color: "white",
                  }}
                />
              }
            >
              {onLinkTitle}
            </Button>
          </QuickSearchToolbar>
        </Box>
      );
    }
    return <div></div>;
  };

  return (
    <div style={{ width: "100%" }}>
      <DataGridStyled
        loading={loading}
        rows={rows}
        columns={[...dataColumns]}
        pageSize={pageSize}
        rowsPerPageOptions={[5]}
        components={{
          Toolbar: (titleProps) => renderToolbarComponent(titleProps),
        }}
        componentsProps={{
          toolbar: {
            value: searchText,
            title: title,
            onChange: (event: any) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        {...selectionProps}
        disableBoxShadow={disableBoxShadow}
        disableColumnSelector={true}
        disableRowSelectionOnClick
        autoHeight
      />
    </div>
  );
}
