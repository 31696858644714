import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useNavigate } from "react-router";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        padding: "16px 16px",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      },
    }),
  { defaultTheme }
);

export default function Header({
  title,
  newLabelName = "Add New",
  onNew,
  onBack,
  disableDivider = false,
}: {
  title: string;
  newLabelName?: string,
  onNew?: () => void;
  onBack?: () => void;
  disableDivider?: boolean;
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBack = () =>
    onBack ? onBack() : navigate("/dashboard");

  return (
    <Box
      component="div"
      className={classes.root}
      sx={
        disableDivider
          ? {}
          : { border: "none", borderBottom: "2px solid rgb(209 213 219)" }
      }
    >
      <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
        { onBack && (
          <IconButton onClick={handleBack} sx={{ marginRight: "8px" }}>
            <ChevronLeftIcon />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ textAlign: "left" }}>
          {title}
        </Typography>
      </Box>
      { onNew ? (
        <Box component="div">
          <Button
            onClick={onNew}
            startIcon={<AddBoxIcon />}
            color="secondary"
          >
            {newLabelName}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
