import { useQuery } from '@apollo/client';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card } from '@mui/material';
import { GridCallbackDetails, GridColDef, GridRow, GridRowModel, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { dateOnlyISO8601 } from '../../../library/date';
import { gql } from '../../../__generated__';
import { StudentCustomField, StudentsPersonLinkQuery, StudentsPersonLinkQueryVariables } from '../../../__generated__/graphql';
import QuickSearchToolbar, { QuickSearchToolbarProps } from '../../Admin/components/StudentsGrid/SearchBar';
import TablePaginated, { FetchMoreCallbackArgs } from '../components/TablePaginated/TablePaginated';
import usePersonStudentAssignment from './hooks/usePersonStudentAssignment';
import { useSelectedPerson } from './SelectedPersonProvider';
import { useManageModal } from '../components/ManageModal';

export const STUDENTS_PERSON_LINK_QUERY = gql(/* GraphQL */`
    query StudentsPersonLink($personId: UUID!, $query: String, $first: Int, $after: String) {
        students(query: $query, status: ACTIVE, first: $first, after: $after) {
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
            edges {
                cursor
                node {
                    id
                    firstName
                    lastName
                    dob
                    references
                    location {
                        id
                        type
                        name
                        createdAt
                    }
                    persons(ids: [$personId]) {
                        id
                    }
                    studentCustomFields {
                        customField {
                            id
                            name
                        }
                        value
                    }
                }
            }
            totalCount
        }
        locations {
            customFields {
                id
                type
                name
            }
        }
    }
`);

type RouteParams = {
    pid?: string
}

type ActionOnCallback = () => void;

const createCustomField = (customField: any): GridColDef => {
    return {
        field: customField.name ?? 'missing',
        headerName: customField.name ?? 'missing',
        flex: 1,
        renderCell: (params) => {
            const studentCustomField = params.row.studentCustomFields
                .find((x: StudentCustomField) => x.customField?.id === customField.id) as StudentCustomField;

            return studentCustomField 
                ? <>{studentCustomField.value}</> 
                : null;
        }
    }
}

const unique = <T, >(array: T[]): T[] => {
    return Array.from(new Set(array));
}

const mapEdgesToTable = (data: any) => {
    return {
        ...data.node,
        dob: format(parseISO(dateOnlyISO8601(data.node.dob)), 'MM/dd/yyyy')

    }
}

function deltaSelections(a: GridRowSelectionModel, b: GridRowSelectionModel, c: GridRowSelectionModel): GridRowSelectionModel {
    const added = a.filter(x => !b.includes(x));
    const removed = b.filter(x => !a.includes(x));

    console.log('deltaSelections', ([] as GridRowSelectionModel)
    .concat(c.filter(x => !removed.includes(x)))
    .concat(added))

    return ([] as GridRowSelectionModel)
        .concat(c.filter(x => !removed.includes(x)))
        .concat(added);
}

interface PersonTableLinkContainerProps {
    children: React.ReactNode
}

interface PersonTableLinkTableToolbarProps extends QuickSearchToolbarProps {}

interface PersonTableLinkProps {
    onBack: ActionOnCallback
}

interface PesonTableLinkFooterProps {
    saving: boolean,
    disabled: boolean,
    onBack: ActionOnCallback
    onSave: ActionOnCallback
}

const PersonTableLink = ({
    onBack
}: PersonTableLinkProps) => {

    const routeParams = useParams<RouteParams>();

    const { person } = useSelectedPerson(routeParams.pid);

    const { openModal } = useManageModal();

    const {loading, data, fetchMore} = useQuery<StudentsPersonLinkQuery, StudentsPersonLinkQueryVariables>(STUDENTS_PERSON_LINK_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            personId: routeParams?.pid
        }
    });

    const [tableState, setTableState] = useState<{ 
        columns: GridColDef[], 
        rows: GridRowModel[], 
        initialSelectionModel: GridRowSelectionModel,
        selectionModel: GridRowSelectionModel,
        selections: { 
            initial: GridRowSelectionModel,
            pageAssign: GridRowSelectionModel, 
            prevAssign: GridRowSelectionModel,  
            nextAssign: GridRowSelectionModel, 
            unassign: GridRowSelectionModel,
            allSelected: GridRowSelectionModel
        },
    }>({
        columns: [],
        rows: [],
        initialSelectionModel: [],
        selectionModel: [],
        selections: {
            initial: [],
            pageAssign: [], // Contains student IDs that have a relationship with person
            prevAssign: [], // Contains student IDs across page/table
            nextAssign: [], // Contains student IDs used to created a person/student relationship
            unassign: [],   // Contains student IDs used to remove the person/student relationship
            allSelected: [] // Contains student IDs for selections on table/page
        },
    });

    const [saving, setSaving] = useState<boolean>(false);

    const { save } = usePersonStudentAssignment(routeParams.pid ?? '');


    useEffect(() => {
        setTableState(prevState => {
            const columns: GridColDef[] = [
                {
                    field: 'name',
                    headerName: "Child's Name",
                    flex: 1,
                    renderCell: (params) => <>{params.row.firstName} {params.row.lastName}</>
                },
                {
                    field: 'dob',
                    flex: 1,
                    headerName: 'Date of Birth',
                }
            ]

            if (data?.locations[0]?.customFields)
                columns.push(...(data?.locations[0]?.customFields.map(createCustomField) ?? []))

            const selectionModel: GridRowSelectionModel = [];
            for (var i = 0; i < (data?.students?.totalCount ?? 0); i++) {
                const studentEdge = data?.students?.edges ? data?.students?.edges[i] : null;
                if (!studentEdge) continue;

                const isAssigned = studentEdge.node.persons.find(x => x.id === routeParams.pid);
                if (isAssigned) {
                    if (tableState.selections.unassign.find(x => x === studentEdge.node.id)) continue;
                    selectionModel.push(studentEdge.node.id);
                }
            }

            return {
                ...prevState,
                columns,
                rows: data?.students?.edges?.map(mapEdgesToTable) ?? [],
                selectionModel,
                selections: {
                    ...prevState.selections,
                    initial: selectionModel,
                    pageAssign: selectionModel,
                    prevAssign: unique(prevState.selections.prevAssign.concat(selectionModel)),
                    allSelected: selectionModel.concat(prevState.selections.nextAssign)
                }
            }
        });
    }, [loading, data, routeParams]);

    const handleSelectionModelChange = (values: GridRowId[], row: GridCallbackDetails) => {
        console.log('handleSelectionModelChange', values);
        const assign = values.filter(x => !tableState.selectionModel.includes(x));
        const unassign = tableState.selectionModel.filter(x => !values.includes(x));
        
        const uniqAssign = assign.filter(x => !tableState.selections.prevAssign.includes(x));

        setTableState(prevState => {
            
            const nextAssign = unique(deltaSelections(uniqAssign, unassign, prevState.selections.nextAssign));
            const nextUnassign = deltaSelections(unassign, assign, prevState.selections.unassign);
            const selectionModel = {
                ...prevState,
                selectionModel: values,
                selections: {
                    ...prevState.selections,
                    nextAssign,
                    unassign: nextUnassign,
                    allSelected: unique(values.concat(nextAssign).filter(x => x !== unassign[0]))
                }
            }

            return selectionModel;
        })
    }

    const handleFetchMore = (variables: FetchMoreCallbackArgs) => {
        fetchMore({
            variables: {
                personIds: [routeParams.pid],
                query: variables.query,
                after: variables.after,
                first: variables.first
            }
        })
    }

    const handleSave = async () => {

        // In theory, this save event shouldn't fire because the save button should be
        // disabled. However, for completeness sake, we exit the event early if 
        // there's nothing to save.
        if (tableState.selections.nextAssign.length === 0 && tableState.selections.unassign.length === 0)
            return;

        setSaving(true);

        // When not in development mode this debug code is removed (tree shaken).
        if (process.env.NODE_ENV === 'development') {
            console.group('handleSave');
            console.log('Assign', tableState.selections.nextAssign);
            console.log('Unassign', tableState.selections.unassign);
            console.groupEnd();
        }

        await save(tableState.selections.nextAssign, tableState.selections.unassign);

        setTableState(prevState => ({
            ...prevState,
            selections: { 
                ...prevState.selections,
                pageAssign: [],
                prevAssign: [], 
                nextAssign: [], 
                unassign: [] 
            }
        }))

        setSaving(false);

        openModal({
            type: 'SAVED_ASSIGNMENTS',
            onConfirm: undefined,
            cancelButtonLabel: 'Close'
        });
    }

    return (
        <PersonTableLink.Container>
            <TablePaginated
                loading={loading}
                renderToolbar={({searchQuery, onSearch, onSearchClear}) => {
                    return (
                        <PersonTableLink.TableToolbar 
                        title={!loading 
                            ? `Link Child for ${person?.firstName} ${person?.lastName}`
                            : 'Loading...'
                        } 
                        value={searchQuery}
                        onChange={onSearch}
                        onClickBack={onBack} 
                        clearSearch={onSearchClear} />
                    )
                }}
                slots={{
                    row: (props) => {
                        const selected = tableState.selections.initial.includes(props.row.id);
                        const found = props.row.persons.find((x: any) => x.id === person?.id);
                        /*
                         * `props.selected` is not true when the row is disabled using `isRowSelectable` 🤦‍♂️
                         * so to achieve the desired result, we look at the intial selection modal i.e., 
                         * all identified person/student connections found at time of loeading the
                         * component
                         */
                        const disabled = props.row.references <= 1 && selected && found;

                        const handleDisabledClick = () => {
                            openModal({ type: 'NOT_ENOUGH_REFERENCES' })
                        };

                        return (
                            <div 
                                style={{  
                                    background: disabled ? '#F5F5F5' : 'initial',
                                    color: disabled ? '#777' : 'initial', 
                                    cursor: disabled ? 'not-allowed' : 'default' 
                                }}
                                onClick={disabled ? handleDisabledClick : undefined}>
                                <GridRow {...props} />
                            </div>
                        )
                    }
                }}
                isRowSelectable={(params) => {
                    const selected = tableState.selections.initial.includes(params.row.id);
                    const found = params.row.persons.find((x: any) => x.id === person?.id);
                    const disabled = !(params.row.references <= 1 && found && selected);
                    return disabled;
                }}
                pageInfo={data?.students?.pageInfo}
                totalRows={data?.students?.totalCount ?? 0}
                columns={tableState.columns}
                rows={tableState.rows} 
                pageSize={10} 
                selectionProps={{
                    rowSelectionModel: tableState.selections.allSelected,
                    onRowSelectionModelChange: handleSelectionModelChange
                }}
                disableBoxShadow={true}
                checkboxSelection={true}
                onFetchMore={handleFetchMore}/>
            <PersonTableLink.Footer 
                disabled={(tableState.selections.nextAssign.length === 0 && tableState.selections.unassign.length === 0)} 
                saving={saving} 
                onBack={onBack} 
                onSave={handleSave} />
        </PersonTableLink.Container>
    )
}

PersonTableLink.TableToolbar = (props: PersonTableLinkTableToolbarProps) => {
    return (
        <Box component="div">
            <QuickSearchToolbar {...props} />      
        </Box>
    );
}

PersonTableLink.Container = ({ children }: PersonTableLinkContainerProps): React.ReactElement => {
    return (
        <Card
            elevation={4}
            style={{
                width: "100%",
                height: "auto",
            }}
        >
            <>{children}</>
        </Card>
    )
}

PersonTableLink.Footer = ({ disabled, saving, onSave, onBack }: PesonTableLinkFooterProps): React.ReactElement => {
    return (
        <Box
            component="div"
            sx={{ padding: "16px", display: "flex", justifyContent: "flex-end" }}
        >
            <Button
                disableElevation
                variant="contained"
                size="large"
                sx={{
                    marginRight: "16px",
                    backgroundColor: "#C4C4C4",
                    "&:hover": {
                    color: "#FFF",
                    backgroundColor: "red.main",
                    },
                }}
                onClick={onBack}
            >
                Cancel
            </Button>
            <LoadingButton
                loading={saving}
                disabled={disabled}
                disableElevation
                variant="contained"
                size="large"
                sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                    backgroundColor: "primary.light",
                    },
                }}
                onClick={onSave}
            >
                Save
            </LoadingButton>
        </Box>
    )
}

export default PersonTableLink;