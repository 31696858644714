import { Box } from "@mui/material";
import QuickSearchToolbar from "../../../Admin/components/StudentsGrid/SearchBar";
import { TableFilter, TableFilterOption } from "../TableFilter";
import { RenderToolbarProps } from "../TablePaginated/TablePaginated";


type Props = {
  title: string;
  onBack?: () => void;
  onFilter?: (option: TableFilterOption) => void;
};

export type TableToolbarProps = Props & RenderToolbarProps;

export default function TableToolbar({
  title,
  searchQuery,
  onBack,
  onSearch,
  onSearchClear,
  onFilter,
}: TableToolbarProps) {
  return (
    <Box component="div" sx={{ display: "flex", backgroundColor: "#fff" }}>
        <QuickSearchToolbar
            value={searchQuery}
            onChange={onSearch}
            onClickBack={onBack}
            clearSearch={onSearchClear}
            title={title}
        >
          { onFilter && <TableFilter defaultValue="ALL" onChange={onFilter} /> }
        </QuickSearchToolbar>
      </Box>
  );
}
