import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { createPortal } from "react-dom";

type Callback = (() => void) | null | undefined;
type ReturnCallback = (() => Promise<string | void> | string | void) | null | void;

type ButtonType = 'WARNING' | 'INFO' | null;
type ModalType = 'EXPIRE_PERSON' | 'EXPIRE_STUDENT' | 'REMOVE_ASSIGNMENT' | 'SAVED_ASSIGNMENTS' | 'REACTIVATE' | 'NOT_ENOUGH_REFERENCES' |  'NO_EMAIL_FOUND' | 'REMOTE_LAUNCH_INITIALIZED' | 'REMOTE_LAUNCH_SUCCESS' | 'PARENT_SEND_GENERAL_INFORMATION_SUCCESS' | 'GENERAL_ERROR' | 'UNKNOWN' | null;
type ModalStatus = 'SAVING' | null;
type ModalReasonCloseType = "backdropClick" | "escapeKeyDown";

type ModalEventType = 'STANDARD' | 'DISABLE_CTA' | 'DISABLE_BACKDROP' | 'DISABLE_BACKDROP_AND_CTA' | null;

type ManageModalState = {
    id?: string | null,
    type: ModalType,
    status?: ModalStatus,
    message?: string | null,
    eventType?: ModalEventType
    onCancel?: ReturnCallback,
    onConfirm?: ReturnCallback,
    cancelButtonLabel?: string,
    confirmButtonLabel?: string,
    confirmButtonType?: ButtonType,
}

export type ManageModalContext = {
    id?: string | null,
    type: ModalType,
    status?: ModalStatus,
    message?: string | null,
    eventType?: ModalEventType,
    openModal: React.Dispatch<React.SetStateAction<ManageModalState>>,
    onCancel?: ReturnCallback,
    onConfirm?: ReturnCallback,
    cancelButtonLabel?: string,
    confirmButtonLabel?: string,
    confirmButtonType?: ButtonType,
    modalResult: ModalResultState | null,
    setModalResult: React.Dispatch<React.SetStateAction<ModalResultState | null>>,
}

export type ModalResultState = { 
    [k: string]: { 
        method: 'cancel' | 'close' | 'confirm', 
        result: Promise<string | null | undefined | void> | string | null | undefined | void, 
    }
}

export const ManageModalContext = React.createContext<ManageModalContext | null>(null);
ManageModalContext.displayName = "ManageModalContextProvider";

export const ManageModalProvider = ({ children }: { children: React.ReactNode }) => {

    const [state, openModal] = useState<ManageModalState>({
        id: null,
        type: null,
        status: null,
        message: null,
        onCancel: null,
        onConfirm: null,
        eventType: "STANDARD",
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Confirm'
    });

    const [modalResult, setModalResult] = useState<ModalResultState | null>(null);

    const memorizedValue = useMemo(() => ({
        ...state, openModal, modalResult, setModalResult
    }), [state, openModal, modalResult, setModalResult]);

    return (
        <ManageModalContext.Provider value={memorizedValue}>
            { children }
        </ManageModalContext.Provider>
    )
}

export const useManageModalContext = () => {
    const context = useContext(ManageModalContext);

    if (!context) 
    throw new Error('Call made outside provider. You must use useManageModal within the ManageModalContext provider.');

    return context;
}

export const useManageModal = () => {
    const { type, modalResult, openModal } = useManageModalContext();

    const closeModal = () => openModal({ id: 'default', type: null });
    
    useEffect(() => {
        if (!modalResult) return;
        console.log('Modal result!', modalResult);
    }, [modalResult]);

    return { type, modalResult, openModal, closeModal } as const;
}

const buttonStyle = {
    maxWidth: "155px",
    fontSize: "12px",
    height: "40px",
    marginTop: "16px",
    color: "#000000",
    background: "#C4C4C4",
    border: "1px solid #000000",
    "&:hover": {
        backgroundColor: "#c4c4c4",
    },
};

export default function ManageModal () {

    const { 
        id = 'default', 
        status, 
        type, 
        eventType = 'STANDARD', 
        openModal, 
        onCancel, 
        onConfirm, 
        cancelButtonLabel, 
        confirmButtonLabel, 
        confirmButtonType, 
        setModalResult 
    } = useManageModalContext()

    const handleCloseModal = (reason: ModalReasonCloseType) => {
        console.log('Backdrop close: ' + eventType);
        if (eventType === 'STANDARD' || eventType === 'DISABLE_CTA') {
            openModal({ id, type: null });
        }
    }

    const handleCancel = () => {
        openModal({ id, type: null })

        if (onCancel) {
            setModalResult(state => ({
                ...state,
                [id as string]: {
                    method: 'cancel',
                    result: onCancel()
                }  
            }))
        } 
    }

    const handleConfirm = () => {
        if (onConfirm) {
            setModalResult(state => ({
                ...state,
                [id as string]: {
                    method: 'confirm',
                    result: onConfirm()
                }  
            }))
        }
    }


    return createPortal(
        <ManageModal.Wrapper open={Boolean(type)} onClose={handleCloseModal}>
            <ManageModal.Body>
                <ModalMessage type={type} />
                <ManageModal.Footer 
                    status={status ?? null}
                    cancelButtonLabel={cancelButtonLabel}
                    confirmButtonLabel={confirmButtonLabel}
                    confirmButtonType={confirmButtonType}
                    onCancel={handleCancel} 
                    onConfirm={!onConfirm ? undefined : handleConfirm} 
                    showCta={['STANDARD', 'DISABLE_BACKDROP'].includes(eventType ?? 'STANDARD')} />
            </ManageModal.Body>
        </ManageModal.Wrapper>,
        document.body);
}

ManageModal.Wrapper = function(
    { 
        open, 
        onClose, 
        children 
    }: { 
        open: boolean, 
        onClose: (reason: ModalReasonCloseType) => void, children:  React.ReactNode 
    }
) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="manage-modal"
            aria-describedby="manage-modal-description"
            sx={{ textAlign: "center" }}
        >
            <>
                { children }
            </>
        </Modal>
    )
}

ManageModal.Body = function({ children }: { children:  React.ReactNode }) {
    return (
        <Box component="div" sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            borderRadius: "10px",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            padding: "24px",
        }}>
            <>
                { children }
            </>
        </Box>
    )
}

ManageModal.Footer = function ({ 
    status, 
    showCta,
    cancelButtonLabel, 
    confirmButtonLabel, 
    confirmButtonType, 
    onCancel, 
    onConfirm 
}: { 
    status: ModalStatus,
    showCta: boolean
    cancelButtonLabel?: string, 
    confirmButtonLabel?: string, 
    confirmButtonType?: ButtonType,
    onCancel: () => void, 
    onConfirm: Callback 
}) {
    return showCta ? (
        <Box
            component="div"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
            }}
        >
            { onConfirm && (
                <LoadingButton
                    loading={status === 'SAVING'}
                    sx={{
                        ...buttonStyle,
                        color: confirmButtonType === "WARNING" ? "#8E1C3B" : "#000000",
                    }}
                    variant="contained"
                    onClick={onConfirm}
                >
                    { confirmButtonLabel ?? 'Confirm' }
                </LoadingButton>
            )}
              <Button
                disabled={status === 'SAVING'}
                sx={buttonStyle}
                color="secondary"
                variant="contained"
                onClick={onCancel}
              >
                { cancelButtonLabel ?? 'Cancel' }
              </Button>{" "}
        </Box>
    ) : <></>;
}

function ModalMessage({ type }: { type: ModalType }) {
    const messageComponents: Record<NonNullable<ModalType>, JSX.Element> = {
        'EXPIRE_PERSON': (
            <Typography>
                Expiring this record will mean the User will no longer have access. Do you wish to proceed?
            </Typography>
        ),
        'EXPIRE_STUDENT': (
            <Typography>
                Launching Tweenscreen will no longer be available for this child if Expired. Do you wish to proceed?
            </Typography>
        ),
        'REMOVE_ASSIGNMENT': (
            <Typography>
                This will remove this User’s access (it will NOT expire the User’s record). Do you wish to proceed?
            </Typography>
        ),
        'SAVED_ASSIGNMENTS': (
            <Typography>
                Successfully saved!
            </Typography>
        ),
        'REACTIVATE': (
            <Typography>
                Please contact your FuturesThrive representative for assistance reactivating a User. Support@FuturesThrive.com
            </Typography>
        ),
        'NOT_ENOUGH_REFERENCES': (
            <Typography>
                This User cannot be removed (de-linked) because at least 1 Admin must be associated with a child record. Another Admin User must be linked before this User can be removed.
            </Typography>
        ),
        'NO_EMAIL_FOUND': (
            <div>
                <Typography>
                    There is no parent email on file for this child.
                </Typography>
                <Typography sx={{ padding: "8px 0px" }}>
                    Please have your Admin update the child’s profile to support the Parent launch option.
                </Typography>
            </div>
        ),
        'REMOTE_LAUNCH_INITIALIZED': (
            <Typography>
                One minute we are sending the link
            </Typography>
        ),
        'REMOTE_LAUNCH_SUCCESS': (
            <div>
                <Typography>
                    SUCCESS!
                </Typography>
                <Typography sx={{ padding: "8px 0px" }}>
                    An email with the child's remote launch link was sent to the parent email on file.
                </Typography>
                <Typography sx={{ paddingBottom: "8px" }}>
                    The link is good for 2 weeks.
                </Typography>
            </div>
        ),
        'PARENT_SEND_GENERAL_INFORMATION_SUCCESS': (
            <div>
                <Typography>
                    SUCCESS!
                </Typography>
                <Typography sx={{ padding: "8px 0px" }}>
                    The general information email has been sent
                </Typography>
            </div>
        ),
        'GENERAL_ERROR': (
            <div>
                <Typography sx={theme => ({ color: theme.palette.error.main, fontWeight: 'bold' })}>
                    Error!
                </Typography>
                <Typography sx={{ padding: "8px 0px" }}>
                    Something has gone wrong. The incident has been logged and sent to support. Please try again in a few.
                </Typography>
            </div>
        ),
        'UNKNOWN': (
            <Typography>
                Message type not recognized
            </Typography>
        )
    }

    return type 
        ? messageComponents[type] 
        : messageComponents['UNKNOWN'];
}