import React from 'react'
import { FieldRenderProps } from "react-final-form";
import { CircularProgress, InputAdornment, TextField } from "@mui/material";

import { GenericInputProps } from "../types";

export type InputFieldProps = FieldRenderProps<GenericInputProps, any>;

const InputField: React.FC<InputFieldProps> = ({ input, meta, loading, helperText, ...props }) => {
    const { invalid, touched, error, submitError } = meta;

    const hasError = invalid && touched;
console.log('Inut posts', props, props.type === 'date', input.type);
    return (
        /* @ts-ignore */
        <TextField {...props} {...input}
            helperText={hasError ? submitError || error : helperText} 
            value={input.value ?? meta.initial}
            error={hasError} 
            InputLabelProps={{ shrink: input.value || input.type === 'date' ? true : false }}
            InputProps={loading 
                ? {
                    endAdornment: (
                        <InputAdornment position="start">
                            <CircularProgress size={24}/>
                        </InputAdornment>
                    )
                } : {}}/>
    )
}

export default InputField;
