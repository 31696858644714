import { create } from "zustand";
import jwtDecode from 'jwt-decode';
import { createJSONStorage, persist, devtools } from "zustand/middleware";
import { storage } from "../../constants/sessionStorage";
import { PersonToken } from "../../context/types";
import { normalizeAuthTokenObject } from "../../library/jwt";

type PersonJwt = {
    roles: string[],
    pid: string;
    lid: string;
};

interface State {
    roles: string[],
    access_token: null,
    token_type: null,
    expires_in: null
}

interface Actions {
    setAuthValues: (data: PersonToken) => void,
    setAuthValuesFromAccessToken: (accessToken: string) => void,
    destroy: () => void
}

type PersonAuthStore = State & Actions;

const initialState: State = {
    roles: [],
    access_token: null,
    token_type: null,
    expires_in: null
};

export function decodedToken(token: string): PersonJwt {
    const decoded = jwtDecode<PersonJwt>(token);
    return decoded;
}

function normalizeValues(x: string) {
    return x.toLocaleUpperCase();
}

function getAuthValuesFromAccessToken(accessToken: string) {
    const { roles = [] } = decodedToken(accessToken);
    return {
        roles: roles.map(normalizeValues)
    };
}

const persisted = persist<PersonAuthStore>(
    (set) => ({
        ...initialState,
        setAuthValues: (data: PersonToken) => set(() => ({
            ...normalizeAuthTokenObject(data),
            ...getAuthValuesFromAccessToken(data.access_token ?? '')
        })),
        setAuthValuesFromAccessToken: (accessToken: string) => set((state) => ({
            ...state,
            ...getAuthValuesFromAccessToken(accessToken)
        })),
        destroy: () => set(() => initialState),
    }),
    {
        name: storage.names.PERSON_AUTH,
        storage: createJSONStorage(() => sessionStorage),

        // Must ignore because can't figure out what type this function is returning
        // It appears it's `Partial<PersonAuthStore>` but any attempt to suggest
        // is met with error. This function is not exposed else where, so
        // ignoring this useless error is fine.
        /* @ts-ignore */
        partialize: ({ access_token, token_type, expires_in }) => ({
            access_token, 
            token_type,
            expires_in 
          }),
    }
)

export default process.env.NODE_ENV === "development"
    ? create<PersonAuthStore>()(devtools(persisted))
    : create<PersonAuthStore>()(persisted);

