import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, AppBar, Typography, Box, IconButton } from '@mui/material';

import { useSlideInContext  } from "./context/SlideInProvider";

const noop = () => {};

const SlideIn = (props: any) => {
    const { open, disableClose, toggleDrawer, content } = useSlideInContext();
    const handleOnClose = disableClose ? noop : toggleDrawer;
    return (
        <div>
              <Drawer
                anchor="right"
                open={open}   
                onClose={handleOnClose}
                
              >
                  <Box component="div" sx={{ display: 'relative', width: "400px" }}>
                    <AppBar position="fixed" color="secondary" sx={{ width: "400px", height: "65px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Typography variant="h6" noWrap component="div">
                            {content?.header}
                        </Typography>
                    </AppBar>
                    
                    <IconButton disabled={disableClose} onClick={handleOnClose} sx={{ position: "absolute", top: "10px", right: "10px", zIndex: "9999", color: "#fff"}}>
                        <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box component="div" sx={{ height: "calc(100vh - 65px)", width: "400px", 
                //   backgroundColor: "#2D2E35",
                   marginTop: "auto", padding: "24px"}}>
                    <>
                      {content?.content}
                    </>
                  </Box>
              </Drawer>
        </div>
      );
}

export default SlideIn;