import { useEffect, useState } from "react";

import { Box, Button, Paper } from "@mui/material";
import { GridColDef, GridRowModel } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import { format, parseISO } from "date-fns";
import { useLazyQuery } from "@apollo/client";

import { FragmentType, getFragmentData, gql } from "../../../__generated__";
import { PeopleForLink__StudentFragmentFragment, PersonStudentsQuery, PersonStudentsQueryVariables } from "../../../__generated__/graphql";
import QuickSearchToolbar from "../../Admin/components/StudentsGrid/SearchBar";
import { dateOnlyISO8601 } from "../../../library/date";
import TablePaginated from "../components/TablePaginated/TablePaginated";
import { PEOPLE_FOR_LINK__STUDENT_FRAGMENT } from "../../../features/graphql/query/person/fragments/peopleForLink__student";
import { PersonDataType } from "../Person/SelectedPersonProvider";
import TableRemoveButton from "../components/TableRemoveButton";

export const DEFAULT_PAGE_SIZE = 100;

const TABLE_COLUMNS: GridColDef[] = [
    { field: "name", headerName: "Child Last Name, First Name", flex: 1, sortable: false },
    { field: "dob", headerName: "Date of Birth", flex: 1, sortable: false },
];

export const PERSON_STUDENTS_QUERY = gql(/* GraphQL */`
    query PersonStudents(
        $personId: UUID!
        $query: String = null
        $after: String = null
        $first: Int = 100, 
    ) {
        person(id: $personId) {
            id
            students(query: $query, status: ACTIVE, first: $first, after: $after) {
                pageInfo {
                    startCursor
                    endCursor
                    hasPreviousPage
                    hasNextPage
                }
                edges {
                    ... _studentEdgePreview
                }
                totalCount
            }
        }
    }
`);

const PERSON_STUDENT_EDGE_FRAGMENT = gql(/* GraphQL */`
    fragment _studentEdgePreview on StudentsEdge {
        cursor
        node {
            id
            firstName
            lastName
            dob
            references
        }
    }
`);

const mapQueryToTable = (edge: FragmentType<typeof PERSON_STUDENT_EDGE_FRAGMENT>): GridRowModel => {
    const { node } = getFragmentData(PERSON_STUDENT_EDGE_FRAGMENT, edge);
    return {
        ...node,
        name: `${node.lastName} ${node.firstName}`,
        dob: format(parseISO(dateOnlyISO8601(node.dob)), 'MM/dd/yyyy'),
        references: node.references
    }
}

export interface PersonTableLinkPreviewProps {
    selectedPerson?: PersonDataType | null
    removeRowId: string | null,
    onLink: (id: string) => void
    onRemove: (id: string) => void
    onDisabled: (reason: 'NOT_ENOUGH_REFERENCES' | 'UNKNOWN') => void
}

const PersonTableLinkPreview = ({
    selectedPerson,
    removeRowId,
    onLink,
    onRemove,
    onDisabled
}: PersonTableLinkPreviewProps) => {

    const [studentEdge, setStudentEdge] = useState<PeopleForLink__StudentFragmentFragment | null | undefined>(null);
    const [pageTableState, setPageTableState] = useState<{ columns: GridColDef[], rows: GridRowModel[] }>({
        columns: [],
        rows: []
    });

    const [fetchRecords, { data, loading, error, fetchMore }] = useLazyQuery<
        PersonStudentsQuery, 
        PersonStudentsQueryVariables>(PERSON_STUDENTS_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            query: '',
            personId: selectedPerson?.id,
            first: DEFAULT_PAGE_SIZE,
            after: null
        }
    });

    useEffect(() => {
        // if (selectedPerson) return;

        async function fetchResults() {
            await fetchRecords()
        }

        fetchResults();
    }, [])

    useEffect(() => {
        if (!selectedPerson) return;

        const fragment = getFragmentData(PEOPLE_FOR_LINK__STUDENT_FRAGMENT, selectedPerson.students);
        setStudentEdge(fragment);

        // TODO -- Make student edge fragment generic. For now ignore TypeScript error
        setPageTableState(prevState => {
            // @ts-ignore
            const rows = selectedPerson?.students?.edges?.map(mapQueryToTable) ?? [];
            return {
                ...prevState,
                rows
            }
        });
    }, [selectedPerson])

    useEffect(() => {
        if (!onRemove) return;

        setPageTableState(prevState => {
            const columns = [...TABLE_COLUMNS, {
                field: 'remove',
                headerName: 'Remove',
                align: 'center',
                sortable: false,
                width: 100,
                renderCell: (params) => {
                    return (
                        <TableRemoveButton 
                            id={params.row.id} 
                            removing={params.row.id === removeRowId}
                            onRemove={onRemove} 
                            onDisabled={() => {
                                if ((params.row.references ?? 0) <= 1) {
                                    return onDisabled("NOT_ENOUGH_REFERENCES");
                                }

                                onDisabled("UNKNOWN")
                            }}
                            disabled={params.row.references <= 1} 
                        />
                    );
                }
            } as GridColDef];

            return {
                ...prevState,
                columns
            }
        })
    }, [onRemove]);

    const handleLink = (id: string) => {
        if (!onLink) return;
        onLink(id)
    }

    return (
        <Paper component="div" variant="outlined" style={{ marginTop: 10 }}>
            <TablePaginated 
                renderToolbar={({ searchQuery, onSearch, onSearchClear}) => {
                    return (
                        <Box component="div">
                            <QuickSearchToolbar 
                                value={searchQuery}
                                clearSearch={onSearchClear} 
                                onChange={onSearch}>
                                <Button
                                    color="secondary"
                                    onClick={x => handleLink(selectedPerson?.id ?? '')}
                                    startIcon={
                                        <AddIcon
                                        style={{
                                            backgroundColor: "#253D93",
                                            borderRadius: "100%",
                                            color: "white",
                                        }}
                                        />
                                    }
                                >
                                    Link Child
                                </Button>
                            </QuickSearchToolbar>
                        </Box>
                    )
                }}
                loading={loading}
                pageInfo={data?.person.students?.pageInfo} 
                totalRows={data?.person.students?.totalCount ?? 0} 
                pageSize={10}
                columns={pageTableState.columns}
                rows={data?.person?.students?.edges?.map(mapQueryToTable) ?? []}
                onFetchMore={(variables) => {
                    if (selectedPerson?.id) {
                        fetchRecords({
                            variables: {
                                personId: selectedPerson?.id ?? '',
                                query: variables.query,
                                first: variables.first
                            } 
                        });
                    }
                }}
                disableBoxShadow={true}
            />
        </Paper>
    )
}

export default PersonTableLinkPreview;