import create from 'zustand';
import { persist } from 'zustand/middleware';

export interface LocationModel {
  id: null | string
}

export interface PersonModel {
  id: null | string,
  company: {
    id: null | string,
    activeLocation: null | LocationModel,
    locations: LocationModel[]
  }
}

export interface PersonState {
  id: null | string,
  company: {
    id: null | string,
    activeLocation: null | LocationModel,
    locations: LocationModel[]
  },
  setPerson: (data: PersonModel) => void,
  setLocationId: (id: string) => void,
  destroy: () => void
}

const usePersonStore = create<PersonState>()(
  /* @ts-ignore */
  persist(
    (set) => ({
      id: null,
      company: {
        id: null,
        activeLocation: null,
        locations: []
      },
      setPerson: (data: PersonModel) => set(() => data),
      setLocationId: (id: string) => set((state) => ({
        ...state,
        id: state.company.id,
        company: {
          id: null,
          activeLocation: state.company.locations.find(x => x.id === id) ?? null,
          locations: [] as LocationModel[]
        }
      })),
      destroy: () => set(() => ({
        id: null,
        company: {
          id: null,
          activeLocation: null,
          locations: []
        }
      }))
    }),
    {
      name: 'active-person',
      getStorage: () => sessionStorage
    }
  ),
);

export default usePersonStore;