import * as React from "react";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        padding: "8px 16px",
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        border: "none",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

export interface QuickSearchToolbarProps {
  title?: string;
  value: string;
  children?: React.FunctionComponent | Element | JSX.Element;
  onChange: (event: any) => void;
  onClickBack?: () => void;
  clearSearch: () => void;
}

export default function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const { title, onClickBack } = props;
  const classes = useStyles();
  return (
    <Box component="div" className={classes.root}>
      <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
        {onClickBack && (
          <IconButton onClick={onClickBack} sx={{ marginRight: "8px" }}>
            <ChevronLeftIcon />
          </IconButton>
        )}
        {title && (
          <Typography variant="h4" sx={{ textAlign: "left" }}>
            {title}
          </Typography>
        )}
        <>
          {props.children}
        </>
      </Box>
      <TextField
        autoFocus
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="large"
              sx={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
}
