import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Collapse,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import DefaultContainer from "../../components/DefaultContainer";
import usePersonAuthStore from "../Admin/personAuthStore";
import { useNavigate } from "react-router";

interface Props {}

function LoginPage(props: Props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false)

  const navigate = useNavigate();

  const setPersonAuthToken = usePersonAuthStore(state => state.setAuthValues);
  const accessToken = usePersonAuthStore(state => state.access_token);

  const handleChange = (name:string) => (evt: React.ChangeEvent<HTMLInputElement>): void => {
    setShowError(false)
    if (name === "password") {
      setPassword(evt.target.value);
    } else {
      setUsername(evt.target.value);
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    navigate("/dashboard", { replace: true })
  }, [])

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {

    evt.preventDefault();
  
    setSubmitting(true);
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("grantType", "password");

    axios
      .post(`${window.__env.API_URL}/api/oauth/authenticate`, formData, { 
        headers: {
          // need to work in firefox
          "access-control-allow-origin": "*"
        }
      })
      .then((res: any) => {
        setPersonAuthToken(res.data);
        navigate("/admin", { replace: true })
      })
      .catch(function (error) {
        if (error.response) {
          setError("Login failed. Email or password is incorrect");
        } else if (error.request) {
          // The request was made but no response was received
          setError("Connection refused. Please try again later");
        } else {
          // Something happened in setting up the request that triggered an Error
          setError("Connection refused. Please try again later");
        }
        setSubmitting(false);
        setShowError(true)
      });
  };

  // if (accessToken)
  //   return <Redirect to="/dashboard" />

  return (
    <div
      className="App"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <DefaultContainer
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: "40px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "200px",
                width: "500px",
                background: `url(${process.env.PUBLIC_URL}/assets/FT_logo.png)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              height: "400px",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <form onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{ maxWidth: "400px" }}>
              <Grid item xs={12}>
                <TextField
                  label="Username"
                  value={username}
                  onChange={handleChange("username")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                type="password"
                  label="Password"
                  value={password}
                  onChange={handleChange("password")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button color="secondary" onClick={() => navigate("/forgot-password")}> Forgot your password?</Button>  
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Collapse in={showError}>
                  <Typography color="error">{error}</Typography>
                </Collapse>
                {submitting ? (
                  <LoadingButton
                    loading={submitting}
                    sx={{ marginTop: "32px" }}
                    variant="contained"
                  >
                    Login
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!Boolean(password && username)}
                    sx={{ marginTop: "32px" }}
                  >
                    Login
                  </Button>
                )}
              </Grid>
            </Grid>
            </form>
          </Grid>
        </Grid>
      </DefaultContainer>
    </div>
  );
}

export default LoginPage;
