import * as React from "react";
import { Divider, AppBar as MuiAppbar } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";

import { useSlideInContext } from "../SlideIn/context/SlideInProvider";
import { useWorkListContext } from "../../../../context/workLists";
import { CHANGE_PASSWORD } from "../SlideIn/context/contentTypes";
import usePersonStore from "../../personStore";
import usePersonAuthStore from "../../personAuthStore";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const USER_ROLE_ADMIN = "ADMIN";
const USER_ROLE_STANDARD = "USER";

function AppMenu({ menuItems, roles, ...rest }: any) {
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorElement(event.currentTarget);
  }

  const handleCloseMenu = () => {
    setMenuAnchorElement(null);
  }

  return (
    /* @ts-ignore */
    <Box component="div" {...rest}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleOpenMenu}
      >
        <MenuIcon sx={{ fontSize: "36px" }} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={menuAnchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(menuAnchorElement)}
        onClose={handleCloseMenu}
      >
        {menuItems
          .filter(
            (x: any) =>
              roles.includes("ADMIN") ||
              (roles.includes("STANDARD") &&
                x.requiredRole !== "ADMIN")
          )
          .map((item: any) => item.id === 'bac2d789-c2f0-40ae-90c5-e52f33ad0323'
            ? [
                <Divider />,
                <MenuItem key={item.id} onClick={item.onClick}>
                  {item.label}
                </MenuItem>
              ]
            : [
              <MenuItem key={item.id} onClick={item.onClick}>
                {item.label}
              </MenuItem>
              ]
            )}
      </Menu>
    </Box>
  )
}

export default function AppBar() {
  const navigate = useNavigate();
  const { username } = useWorkListContext();
  const { handleContent } = useSlideInContext();

  const destroyPersonStore = usePersonStore((state) => state.destroy);

  const roles = usePersonAuthStore((state) => state.roles);
  const destroyPersonAuthToken = usePersonAuthStore((state) => state.destroy);

  const handleSignout = () => {
    destroyPersonAuthToken();
    destroyPersonStore();
    navigate("/login");
  };

  const navigation = [
    {
      id: "8020e04f-4e73-4d6d-a6bc-d0483e574e9f",
      label: "Home",
      requiredRole: USER_ROLE_STANDARD,
      onClick: () => {
        navigate("/dashboard")
      },
    },
    {
      id: "1e4b3436-bf08-4a5d-930a-743865dac5ec",
      label: "Change Password",
      requiredRole: USER_ROLE_STANDARD,
      onClick: () => {
        handleContent(CHANGE_PASSWORD);
      },
    },
    {
      id: "6bd2db90-4ee4-4bfb-8784-4f99f87a1141",
      label: "Manage Children",
      requiredRole: USER_ROLE_ADMIN,
      onClick: () => {
        navigate("/manage/children");
      },
    },
    {
      id: "3212a84f-1c4c-46d1-bfbe-80dea416135d",
      label: "Manage Users",
      requiredRole: USER_ROLE_ADMIN,
      onClick: () => {
        navigate("/manage/users");
      },
    },
    {
      id: 'bac2d789-c2f0-40ae-90c5-e52f33ad0323',
      label: "Sign Out",
      requiredRole: USER_ROLE_STANDARD,
      onClick: () => {
        handleSignout();
      },
    },
  ];

  return (
    <MuiAppbar color="secondary" position="static">
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={2}>
            <Link to="/admin">
              <Box
                component="div"
                sx={{
                  
                  marginTop: 1.3,
                  marginBottom: 1.5,
                  height: "45px",
                  width: "45px",
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/ft_logo_sm.jpg)`,
                  backgroundSize: "cover",
                  borderRadius: "15px",
                }} />
            </Link>
          </Grid>
          <Grid item xs={10} sx={{ display: "flex", alignItems: "center", justifyContent: 'end' }}>
            <Typography variant="h5" sx={{ fontWeight: "300" }}>
              {" "}
              {username ? `Welcome, ${username}` : ""}{" "}
            </Typography>
            <AppMenu sx={{ marginLeft: 1 }} menuItems={navigation} roles={roles} />
          </Grid>
        </Grid>
      </Container>
    </MuiAppbar>
  );
}
