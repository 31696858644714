import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useCallback, useEffect, useState } from "react";

const filterOptions = {
    ALL: "All Records",
    ACTIVE: "Active Only",
    EXPIRED: "Expired Only"
} as const;

export type TableFilterOption = keyof typeof filterOptions;

export type TableFilterProps = {
    defaultValue?: TableFilterOption | null,
    onChange?: (value: TableFilterOption) => void
}

export default function TableFilter({ defaultValue = null, onChange }: TableFilterProps) {

    const [selectedOption, setSelectedOption] = useState<TableFilterOption>(defaultValue ?? "ALL");

    const onSelectionChange = useCallback((option: TableFilterOption) => {
        return onChange ? onChange(option) : () => {};
    }, [selectedOption, onChange]);

    useEffect(() => {
        onSelectionChange(selectedOption);
    }, [selectedOption]);

    const handleSelectionChange = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value as TableFilterOption;
        setSelectedOption(selectedValue);
    }

    return (
        <Box component="span" sx={{ marginLeft: "32px" }}>
            <FormControl fullWidth>
                <Select
                    id="table-filter"
                    value={selectedOption}
                    defaultValue={defaultValue ?? "ALL"}
                    onChange={handleSelectionChange}
                    sx={{ height: "40px" }}
                >
                    {Object.keys(filterOptions).map(key => (
                        <MenuItem key={key} value={key as TableFilterOption}>
                            { filterOptions[key as TableFilterOption] }
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    )
}